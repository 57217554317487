/**
 *
 * AppLoading
 *
 */

import React, { memo } from 'react'
import UiLoadingFull from '../UiLoadingFull'
import { UlapLogo } from '../../asset/image/index'
import PropTypes from 'prop-types'

function AppLoading() {
  return (
    <div style={{ height: '100vh' }}>
      <UiLoadingFull />
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          height: 'calc(100vh - 4px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <UlapLogo />
      </div>
    </div>
  )
}

AppLoading.propTypes = {}

export default memo(AppLoading)
