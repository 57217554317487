import {
    CLOUD_SENSE_CREATE_APPLICATION,
    CLOUD_SENSE_CREATE_APPLICATION_FAILURE,
    CLOUD_SENSE_CREATE_APPLICATION_SUCCESS,
    CLOUD_SENSE_GET_APPLICATIONS,
    CLOUD_SENSE_GET_APPLICATIONS_FAILURE,
    CLOUD_SENSE_GET_APPLICATIONS_SUCCESS,
    CLOUD_SENSE_DELETE_APPLICATION,
    CLOUD_SENSE_DELETE_APPLICATION_FAILURE,
    CLOUD_SENSE_DELETE_APPLICATION_SUCCESS,
} from './CloudSenseAppContants'

const initialState = {
    isCreating: false,
    isGetting: false,
    isDeleting: false
}

export default function reducer(state=initialState, action) {
    switch(action.type) {
        case CLOUD_SENSE_CREATE_APPLICATION:
            return {
                ...state,
                isCreating: true
            }
        case CLOUD_SENSE_CREATE_APPLICATION_FAILURE:
            return {
                ...state,
                isCreating: false
            }
        case CLOUD_SENSE_CREATE_APPLICATION_SUCCESS:
            return {
                ...state,
                isCreating: false
            }
        case CLOUD_SENSE_GET_APPLICATIONS:
            return {
                ...state,
                isGetting: true
            }
        case CLOUD_SENSE_GET_APPLICATIONS_FAILURE:
            return {
                ...state,
                isGetting: false
            }
        case CLOUD_SENSE_GET_APPLICATIONS_SUCCESS:
            return {
                ...state,
                isGetting: false,
                ...action.payload
            }
        case CLOUD_SENSE_DELETE_APPLICATION:
            return {
                ...state,
                isDeleting: true
            }
        case CLOUD_SENSE_DELETE_APPLICATION_FAILURE:
            return {
                ...state,
                isDeleting: false
            }
        case CLOUD_SENSE_DELETE_APPLICATION_SUCCESS:
            return {
                ...state,
                isDeleting: false
            }
        default:
            return state
    }
}