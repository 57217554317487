import {
  CLOUD_SENSE_CREATE_WORKSPACE,
  CLOUD_SENSE_CREATE_WORKSPACE_SUCCESS,
  CLOUD_SENSE_CREATE_WORKSPACE_FAILURE,
  CLOUD_SENSE_GET_WORKSPACE,
  CLOUD_SENSE_GET_WORKSPACE_SUCCESS,
  CLOUD_SENSE_GET_WORKSPACE_FAILURE,
  CLOUD_SENSE_GET_WORKSPACE_USERS,
  CLOUD_SENSE_GET_WORKSPACE_USERS_SUCCESS,
  CLOUD_SENSE_GET_WORKSPACE_USERS_FAILURE,
  CLOUD_SENSE_ADD_WORKSPACE_USERS,
  CLOUD_SENSE_ADD_WORKSPACE_USERS_SUCCESS,
  CLOUD_SENSE_ADD_WORKSPACE_USERS_FAILURE,
} from './CloudSenseWorkspaceConstants'

const initialState = {
  isCreating: false,
  isGetting: false,
  isUsersGetting: false,
  users: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CLOUD_SENSE_CREATE_WORKSPACE:
      return {
        ...state,
        isCreating: true,
      }
    case CLOUD_SENSE_CREATE_WORKSPACE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        ...action.payload,
      }
    case CLOUD_SENSE_CREATE_WORKSPACE_FAILURE:
      return {
        ...state,
        isCreating: false,
      }
    case CLOUD_SENSE_GET_WORKSPACE:
      return {
        ...state,
        isGetting: true,
      }
    case CLOUD_SENSE_GET_WORKSPACE_SUCCESS:
      return {
        ...state,
        isGetting: false,
        ...action.payload,
      }
    case CLOUD_SENSE_GET_WORKSPACE_FAILURE:
      return {
        ...state,
        isGetting: false,
      }
    case CLOUD_SENSE_GET_WORKSPACE_USERS: {
      return {
        ...state,
        isUsersGetting: true,
      }
    }
    case CLOUD_SENSE_GET_WORKSPACE_USERS_SUCCESS: {
      return {
        ...state,
        isUsersGetting: false,
        users: action.payload,
      }
    }
    case CLOUD_SENSE_GET_WORKSPACE_USERS_FAILURE: {
      return {
        ...state,
        isUserGetting: false,
        ...action.payload,
      }
    }
    case CLOUD_SENSE_ADD_WORKSPACE_USERS: {
      return {
        ...state,
        isCreating: true,
      }
    }
    case CLOUD_SENSE_ADD_WORKSPACE_USERS_SUCCESS: {
      return {
        ...state,
        isCreating: false,
        ...action.payload,
      }
    }
    case CLOUD_SENSE_ADD_WORKSPACE_USERS_FAILURE: {
      return {
        ...state,
        isUserGetting: false,
        ...action.payload,
      }
    }
    default:
      return state
  }
}
