export const CLOUD_SENSE_CREATE_WORKSPACE = 'CLOUD_SENSE_CREATE_WORKSPACE'
export const CLOUD_SENSE_CREATE_WORKSPACE_SUCCESS =
  'CLOUD_SENSE_CREATE_WORKSPACE_SUCCESS'
export const CLOUD_SENSE_CREATE_WORKSPACE_FAILURE =
  'CLOUD_SENSE_CREATE_WORKSPACE_FAILURE'
export const CLOUD_SENSE_GET_WORKSPACE = 'CLOUD_SENSE_GET_WORKSPACE'
export const CLOUD_SENSE_GET_WORKSPACE_SUCCESS =
  'CLOUD_SENSE_GET_WORKSPACE_SUCCESS'
export const CLOUD_SENSE_GET_WORKSPACE_FAILURE =
  'CLOUD_SENSE_GET_WORKSPACE_FAILURE'
export const CLOUD_SENSE_GET_WORKSPACE_USERS = 'CLOUD_SENSE_GET_WORKSPACE_USERS'
export const CLOUD_SENSE_GET_WORKSPACE_USERS_SUCCESS =
  'CLOUD_SENSE_GET_WORKSPACE_USERS_SUCCESS'
export const CLOUD_SENSE_GET_WORKSPACE_USERS_FAILURE =
  'CLOUD_SENSE_GET_WORKSPACE_USERS_FAILURE'
export const CLOUD_SENSE_ADD_WORKSPACE_USERS =
  'CLOUD_SENSE_CREATE_ADD_WORKSPACE_USERS'
export const CLOUD_SENSE_ADD_WORKSPACE_USERS_SUCCESS =
  'CLOUD_SENSE_ADD_WORKSPACE_USERS_SUCCESS'
export const CLOUD_SENSE_ADD_WORKSPACE_USERS_FAILURE =
  'CLOUD_SENSE_ADD_WORKSPACE_USERS_FAILURE'
