import {
    CLOUD_SENSE_SAVE_USER_PROFILE,
    CLOUD_SENSE_SAVE_USER_PROFILE_SUCCESS,
    CLOUD_SENSE_SAVE_USER_PROFILE_FAILURE,
    CLOUD_SENSE_GET_USER_PROFILE,
    CLOUD_SENSE_GET_USER_PROFILE_SUCCESS,
    CLOUD_SENSE_GET_USER_PROFILE_FAILURE
} from './CloudSenseUserConstants'

const initialState = {
    isSaving: false,
    isGetting: false
}

export default function reducer(state=initialState, action) {
    switch(action.type) {
        case CLOUD_SENSE_SAVE_USER_PROFILE:
            return {
                ...state,
                isSaving: true
            }
        case CLOUD_SENSE_SAVE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                isSaving: false,
                ...action.payload
            }
        case CLOUD_SENSE_SAVE_USER_PROFILE_FAILURE:
            return {
                ...state,
                isSaving: false
            }
        case CLOUD_SENSE_GET_USER_PROFILE:
            return {
                ...state,
                isGetting: true
            }
        case CLOUD_SENSE_GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                isGetting: false,
                ...action.payload
            }
        case CLOUD_SENSE_GET_USER_PROFILE_FAILURE:
            return {
                ...state,
                isGetting: false
            }
        default:
            return state
    }
}