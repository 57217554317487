import {
    AWS_CREATE_BUCKET,
    AWS_CREATE_BUCKET_FAILURE,
    AWS_CREATE_BUCKET_SUCCESS,
    AWS_DELETE_BUCKET,
    AWS_DELETE_BUCKET_FAILURE,
    AWS_DELETE_BUCKET_SUCCESS,
    AWS_LIST_BUCKETS,
    AWS_LIST_BUCKETS_FAILURE,
    AWS_LIST_BUCKETS_SUCCESS,

    AWS_CREATE_WORKSPACE_BUCKET,
    AWS_CREATE_WORKSPACE_BUCKET_SUCCESS,
    AWS_CREATE_WORKSPACE_BUCKET_FAILURE,
    AWS_DELETE_WORKSPACE_BUCKET_SUCCESS,
    AWS_DELETE_WORKSPACE_BUCKET_FAILURE,
    AWS_DELETE_WORKSPACE_BUCKET,
    AWS_LIST_WORKSPACE_BUCKETS,
    AWS_LIST_WORKSPACE_BUCKETS_SUCCESS,
    AWS_LIST_WORKSPACE_BUCKETS_FAILURE
} from './AwsStorageConstants'

const initialState = {
    buckets: [],
    workspaceBuckets: [],
    isListing: false,
    isCreating: false,
    isDeleting: false
}

export default function reducer(state=initialState, action) {
    switch(action.type) {
        case AWS_CREATE_BUCKET:
            return {
                ...state,
                isCreating: true
            }
        case AWS_CREATE_BUCKET_FAILURE:
            return {
                ...state,
                isCreating: false
            }
        case AWS_CREATE_BUCKET_SUCCESS:
            return {
                ...state,
                isCreating: false
            }
        case AWS_DELETE_BUCKET:
            return {
                ...state,
                isDeleting: true
            }
        case AWS_DELETE_BUCKET_SUCCESS:
            return initialState
        case AWS_DELETE_BUCKET_FAILURE:
            return {
                ...state,
                isDeleting: false
            }
        case AWS_LIST_BUCKETS:
            return {
                ...state,
                isListing: true
            }
        case AWS_LIST_BUCKETS_SUCCESS:
            return {
                ...state,
                buckets: action.payload,
                isListing: false
            }
        case AWS_LIST_BUCKETS_FAILURE:
            return {
                ...state,
                isListing: false
            }


            
        case AWS_CREATE_WORKSPACE_BUCKET:
            return {
                ...state,
                isCreating: true
            }
        case AWS_CREATE_WORKSPACE_BUCKET_FAILURE:
            return {
                ...state,
                isCreating: false
            }
        case AWS_CREATE_WORKSPACE_BUCKET_SUCCESS:
            return {
                ...state,
                isCreating: false
            }
        case AWS_DELETE_WORKSPACE_BUCKET:
            return {
                ...state,
                isDeleting: true
            }
        case AWS_DELETE_WORKSPACE_BUCKET_SUCCESS:
            return initialState

        case AWS_DELETE_WORKSPACE_BUCKET_FAILURE:
            return {
                ...state,
                isDeleting: false
            }
        case AWS_LIST_WORKSPACE_BUCKETS:
            return {
                ...state,
                isListing: true
            }
        case AWS_LIST_WORKSPACE_BUCKETS_SUCCESS:
            return {
                ...state,
                workspaceBuckets: action.payload,
                isListing: false
            }
        case AWS_LIST_WORKSPACE_BUCKETS_FAILURE:
            return {
                ...state,
                isListing: false
            }
        default:
            return state
    }
}