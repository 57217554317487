/*
 *
 * Container reducer
 *
 */
import produce from 'immer'
import { merge } from '../../utils/object'
import { CONTAINER_MERGE, CONTAINER_MERGE_ADD_ITEMS } from './constants'

export const initialState = {
  records: [],
  isLoading: true,
  isUpdated: false,
  error: '',
}

/* eslint-disable default-case, no-param-reassign */
const containerReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CONTAINER_MERGE:
        return merge(draft, action.payload)
      case CONTAINER_MERGE_ADD_ITEMS:
        merge(draft, action.payload)
        draft.records = [...state.records, ...action.payload.records]
        console.log('draft', draft)
        return draft
    }
  })

export default containerReducer
