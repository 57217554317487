import {
    AZURE_CREATE_CONTAINER,
    AZURE_CREATE_CONTAINER_FAILURE,
    AZURE_CREATE_CONTAINER_SUCCESS,
    AZURE_DELETE_CONTAINER,
    AZURE_DELETE_CONTAINER_FAILURE,
    AZURE_DELETE_CONTAINER_SUCCESS,
    AZURE_GET_CONTAINERS,
    AZURE_GET_CONTAINERS_FAILURE,
    AZURE_GET_CONTAINERS_SUCCESS,
    AZURE_GET_CONTAINER_BLOBS,
    AZURE_GET_CONTAINER_BLOBS_FAILURE,
    AZURE_GET_CONTAINER_BLOBS_SUCCESS
} from './AzureStorageConstants'

const initialState = {
    containers: [],
    isFetching: false,
    isCreating: false,
    isDeleting: false
}

export default function reducer(state=initialState, action) {
    switch(action.type) {
        case AZURE_CREATE_CONTAINER:
            return {
                ...state,
                isCreating: true
            }
        case AZURE_CREATE_CONTAINER_FAILURE:
            return {
                ...state,
                isCreating: false
            }
        case AZURE_CREATE_CONTAINER_SUCCESS:
            return {
                ...state,
                isCreating: false
            }
        case AZURE_DELETE_CONTAINER:
            return {
                ...state,
                isDeleting: true
            }
        case AZURE_DELETE_CONTAINER_SUCCESS:
            return initialState
        case AZURE_DELETE_CONTAINER_FAILURE:
            return {
                ...state,
                isDeleting: false
            }
        case AZURE_GET_CONTAINERS:
            return {
                ...state,
                isFetching: true
            }
        case AZURE_GET_CONTAINERS_SUCCESS:
            return {
                ...state,
                containers: action.payload,
                isFetching: false
            }
        case AZURE_GET_CONTAINERS_FAILURE:
            return {
                ...state,
                isFetching: false
            }
        case AZURE_GET_CONTAINER_BLOBS:
            return {
                ...state,
                isFetching: true
            }
        case AZURE_GET_CONTAINER_BLOBS_SUCCESS:
            return {
                ...state,
                bucketObjects: action.payload,
                isFetching: false
            }
        case AZURE_GET_CONTAINER_BLOBS_FAILURE:
            return {
                ...state,
                isFetching: false
            }
        default:
            return state
    }
}