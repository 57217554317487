import { put, call, select , takeLatest } from 'redux-saga/effects'
import {
    IBM_SAVE_CREDENTIALS,
    IBM_DELETE_CREDENTIALS,
    IBM_GET_CREDENTIALS
} from './ibmAuthConstants';

import {
    ibmSaveCredentialsSuccess,
    ibmSaveCredentialsFailure,
    ibmDeleteCredentialsFailure,
    ibmDeleteCredentialsSuccess,
    ibmGetCredentialsSuccess,
    ibmGetCredentialsFailure
} from './ibmAuthActions'
import {
    putIBMCredentials,
    deleteIBMCredentials,
    getIBMCredentials
} from './ibmAuthApi'
import {
    makeSelectAuthUserMgtToken,
    makeSelectAuthSelectedOrganizationId
} from '../../../../containers/Auth/selectors'

export default function* ibmAuthMiddlware () {
    yield takeLatest(IBM_SAVE_CREDENTIALS, saveCredentials)
    yield takeLatest(IBM_DELETE_CREDENTIALS, deleteCredentials)
    yield takeLatest(IBM_GET_CREDENTIALS, getCredentials)
}

export function* saveCredentials (action) {
    
    const token = yield select(makeSelectAuthUserMgtToken())
    const organizationId = yield select(makeSelectAuthSelectedOrganizationId())

    try {
        const response = yield call(putIBMCredentials, action.payload.apiKey, token, organizationId)
        if (response.ok) {
            yield put(ibmSaveCredentialsSuccess(action.payload))
        } else {
            yield put(ibmSaveCredentialsFailure())
        }
    }
    catch(error) {
        yield put(ibmSaveCredentialsFailure())
    }
}

export function* getCredentials (action) {

    const token = yield select(makeSelectAuthUserMgtToken())
    const organizationId = yield select(makeSelectAuthSelectedOrganizationId())

    try {
        const response = yield call(getIBMCredentials, token, organizationId)
        if (response.ok) {
            const json = yield response.json()

            // response,ok does not mean the IBM API key is stored in the database
            // we need to check the API key not null
            if (json.apiKey) {
                yield put(ibmGetCredentialsSuccess(json))
            } else {
                yield put(ibmGetCredentialsFailure())
            }

        } else {
            yield put(ibmGetCredentialsFailure())
        }
    }
    catch(error) {
        console.log(error)
        yield put(ibmGetCredentialsFailure())
    }

}

export function* deleteCredentials (action) {

    const token = yield select(makeSelectAuthUserMgtToken())
    const organizationId = yield select(makeSelectAuthSelectedOrganizationId())

    try {
        const response = yield call(deleteIBMCredentials, token, organizationId)
        if (response.ok) {
            yield put(ibmDeleteCredentialsSuccess())
        } else {
            yield put(ibmDeleteCredentialsFailure())
        }
    }
    catch(error) {
        console.log(error)
        yield put(ibmDeleteCredentialsFailure())
    }
}