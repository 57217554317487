/*
 *
 * Spark reducer
 *
 */
import produce from 'immer'
import { merge } from '../../utils/object'
import { SPARK_MERGE, SPARK_REMOVE_BATCH_LIST_ITEM, SPARK_REMOVE_BATCH_LIST_ITEMS } from './constants'

export const initialState = {
  records: null,
  batchlist: [],
  batchLogs: [],
  activeSparkService: [],
  isLoading: false,
  isUpdated: false,
  error: '',
  message: '', // status messaging
}

/* eslint-disable default-case, no-param-reassign */
const sparkReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SPARK_MERGE:
        return merge(draft, action.payload)
      case SPARK_REMOVE_BATCH_LIST_ITEM:
        draft.batchlist = state.batchlist.filter(item => item.id !== action.payload)
        return merge(draft, action.payload)
      case SPARK_REMOVE_BATCH_LIST_ITEMS:
        draft.batchlist = state.batchlist.filter(item => !action.payload.includes(item.id))
        return draft
    }
  })

export default sparkReducer
