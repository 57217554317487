import {
    CLOUD_SENSE_GET_ORG_USERS,
    CLOUD_SENSE_GET_ORG_USERS_FAILURE,
    CLOUD_SENSE_GET_ORG_USERS_SUCCESS,
    CLOUD_SENSE_GET_ORG_WORKSPACES,
    CLOUD_SENSE_GET_ORG_WORKSPACES_FAILURE,
    CLOUD_SENSE_GET_ORG_WORKSPACES_SUCCESS
} from './CloudSenseOrgConstants'

const initialState = {
    workspaces: [],
    users: [],
    isGetting: false
}

export default function reducer(state=initialState, action) {
    switch(action.type) {
        case CLOUD_SENSE_GET_ORG_USERS:
            return {
                ...state,
                isGetting: true
            }
        case CLOUD_SENSE_GET_ORG_USERS_FAILURE:
            return {
                ...state,
                isGetting: false
            }
        case CLOUD_SENSE_GET_ORG_USERS_SUCCESS:
            return {
                ...state,
                isGetting: false,
                users: action.payload.data
            }
        case CLOUD_SENSE_GET_ORG_WORKSPACES:
            return {
                ...state,
                isGetting: true
            }
        case CLOUD_SENSE_GET_ORG_WORKSPACES_FAILURE:
            return {
                ...state,
                isGetting: false
            }
        case CLOUD_SENSE_GET_ORG_WORKSPACES_SUCCESS:
            return {
                ...state,
                isGetting: false,
                workspaces: action.payload
            }
        default:
            return state
    }
}