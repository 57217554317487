const localUrl = 'http://localhost:8088/'
const devUrl = 'https://dev.ulap.co/'

const localMgtUrl = 'http://localhost:5000/api/'
const devMgtUrl = 'https://cloudsense-management-lr55wgcjya-uc.a.run.app/api/'
const prodMgtUrl =
  'https://cloudsense-management-demo-lr55wgcjya-uc.a.run.app/api/'

export const environment = env => {
  let djangoUrl = '/'
  let managementUrl

  switch (env) {
    case 'production':
      managementUrl = prodMgtUrl
      break
    case 'staging':
      managementUrl = devMgtUrl
      break
    default:
      // Change this to repoint local backend
      // djangoUrl = localUrl
      djangoUrl = devUrl
      // managementUrl = localMgtUrl
      managementUrl = devMgtUrl
  }

  return {
    auth: djangoUrl + 'auth/',
    azureResource: managementUrl + 'v1/azure_resource/',
    avatarUpload: djangoUrl + 'uploads/v1/',
    base: djangoUrl,
    clusters: managementUrl + 'v1/cluster',
    users: managementUrl + 'v1/user',
    clustersNode: djangoUrl + 'api/v1/clusters/nodes',
    container: djangoUrl + 'api/v1/container',
    containerList: djangoUrl + 'deployments/v1',
    nodepoolCreate: managementUrl + 'v1/nodepool/create',
    services: managementUrl + 'v1/cluster/services/list',
    mngtCluster: managementUrl + 'v1/cluster',

    dockerhub: managementUrl + 'v1/dockerhub',
    fileUpload: djangoUrl + 'v1/file-uploader',
    gcpServiceAccountKeys: managementUrl + 'v1/user/gcp-service-account-keys/',
    githubApi: djangoUrl + 'user-auth-github/v1/',
    githubRepos: djangoUrl + 'github-repos/v1/',

    newUserConfig: managementUrl + 'v1/user/new-user-config',
    sparkStatus: djangoUrl + 'clusters/spark-list',
    ulapSession: managementUrl + 'v1/user/login',
    cloudsenseRegister: managementUrl + 'v1/user/create',
    userAccount: djangoUrl + 'user-account/v1/',
    userCount: managementUrl + 'v1/user/admin/total',
    userAuthGcp: managementUrl + 'v1/user/user-auth-gcp',
    userAuthAws: managementUrl + 'v1/organization/user_auth_aws',
    userAuthAzure: managementUrl + 'v1/organization/user_auth_azure',
    userAuthDockerhub: managementUrl + 'v1/user/user_auth_dockerhub',
    userCloudProvider: djangoUrl + 'user-cloud-provider/v1/',
    userContainerRegistry: managementUrl + 'v1/user/user-cloud-provider',
    userProfile: managementUrl + 'v1/user/user-profile',
    userOrganizations: managementUrl + 'v1/organization/list',
    organization: managementUrl + 'v1/organization',
    permission: managementUrl + 'v1/permission',
    application: managementUrl + 'v1/application',

    sparkProxy: djangoUrl + 'api/v1/spark/proxy',
    sparkLilySvc: djangoUrl + 'api/v1/spark/lily-svc',
    validator: djangoUrl + 'validator/',
    mgtApi: managementUrl + 'v1/',
  }
}

export default environment(process.env.NODE_ENV)
