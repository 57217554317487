/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import rootAppReducer from './root/service/store/reducer'
import history from 'utils/history'
import languageProviderReducer from './containers/LanguageProvider/reducer'
// import authReducer from './route/auth/service/store/reducer'
import appReducer from './containers/App/reducer'
import utakSessionReducer from './containers/UtakSession/reducer'
import clusterReducer from './containers/Cluster/reducer'
import accountContainerRegistryReducer from './containers/AccountContainerRegistry/reducer'
import userAccountReducer from './containers/UserAccount/reducer'
import accountGeneralReducer from './containers/AccountGeneral/reducer'
import containerReducer from './containers/Container/reducer'
import authReducer from './containers/Auth/reducer'
import accountCloudProviderReducer from './containers/AccountCloudProvider/reducer'
import gcpServiceAccountKeysReducer from './containers/GcpServiceAccountKeys/reducer'
import accountCloudProviderAwsReducer from './containers/AccountCloudProviderAws/reducer'
import sparkReducer from './containers/Spark/reducer'
import accountCloudProviderAzureReducer from './containers/AccountCloudProviderAzure/reducer'
import newUserHomeReducer from './containers/NewUserHome/reducer'
// import ibmReducer from './v2/cloud-sense/ibm/auth/ibmAuthReducers'
import { ibmReducer } from './v2/cloud-sense/ibm'
import cloudSenseUserReducer from './v2/cloud-sense/cloud-sense/user/CloudSenseUserReducers'
import cloudSenseOrganizationReducer from './v2/cloud-sense/cloud-sense/organization/CloudSenseOrgReduers'
import cloudSenseWorkspaceReducer from './v2/cloud-sense/cloud-sense/workspace/CloudSenseWorkspaceReducers'
import awsStorageReducer from './v2/cloud-sense/aws/storage/AwsStorageReducers'
import gcpStorageReducer from './v2/cloud-sense/gcp/storage/GcpStorageReducers'
import azureStorageReducer from './v2/cloud-sense/azure/storage/AzureStorageReducers'
import cloudSenseAppReducer from './v2/cloud-sense/cloud-sense/application/CloudSenseAppReducers'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    accountContainerRegistry: accountContainerRegistryReducer,
    accountCloudProviderAws: accountCloudProviderAwsReducer,
    accountCloudProviderAzure: accountCloudProviderAzureReducer,
    accountCloudProvider: accountCloudProviderReducer,
    accountGeneral: accountGeneralReducer,
    app: appReducer,
    auth: authReducer,
    container: containerReducer,
    cluster: clusterReducer,
    ibm: ibmReducer,
    organization: cloudSenseOrganizationReducer,
    workspace: cloudSenseWorkspaceReducer,
    aws: awsStorageReducer,
    gcp: gcpStorageReducer,
    azure: azureStorageReducer,
    user: cloudSenseUserReducer,
    application: cloudSenseAppReducer,
    language: languageProviderReducer,
    gcpServiceAccountKeys: gcpServiceAccountKeysReducer,
    newUserHome: newUserHomeReducer,
    router: connectRouter(history),
    root: rootAppReducer,
    userAccount: userAccountReducer,
    utakSession: utakSessionReducer,
    spark: sparkReducer,
    ...injectedReducers,
  })

  return rootReducer
}
