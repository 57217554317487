import { put, call, select , takeLatest } from 'redux-saga/effects'
import {
    IBM_CREATE_BUCKET,
    IBM_DELETE_BUCKET,
    IBM_GET_BUCKETS,
    IBM_GET_BUCKET_OBJECTS
} from './IbmStorageConstants';

import {
    ibmCreateBucketFailure,
    ibmCreateBucketSuccess,
    ibmDeleteBucketFailure,
    ibmDeleteBucketSuccess,
    ibmGetBucketsFailure,
    ibmGetBucketsSuccess,
    ibmGetBucketObjectsFailure,
    ibmGetBucketObjectsSuccess
} from './IbmStorageActions'
import {
    getIbmBuckets,
    createIbmBucket,
    getIbmBucketObjects,
    deleteIbmBucket
} from './IbmStorageApi'
import {
    makeSelectAuthUserMgtToken,
    makeSelectAuthSelectedOrganizationId
} from '../../../../containers/Auth/selectors'

export default function* ibmStorageMiddleware () {
    yield takeLatest(IBM_CREATE_BUCKET, createIbmBucketSaga)
    yield takeLatest(IBM_DELETE_BUCKET, deleteIbmBucketSaga)
    yield takeLatest(IBM_GET_BUCKETS, getIbmBucketsSaga)
    yield takeLatest(IBM_GET_BUCKET_OBJECTS, getIbmBucketObjectsSaga)
}

export function* createIbmBucketSaga (action) {
    
    const token = yield select(makeSelectAuthUserMgtToken())
    const organizationId = yield select(makeSelectAuthSelectedOrganizationId())

    try {
        const response = yield call(createIbmBucket, action.payload.apiKey, token, organizationId)
        if (response.ok) {
            yield put(ibmCreateBucketSuccess(action.payload))
        } else {
            yield put(ibmCreateBucketFailure())
        }
    }
    catch(error) {
        yield put(ibmCreateBucketFailure())
    }
}

export function* deleteIbmBucketSaga (action) {

    const token = yield select(makeSelectAuthUserMgtToken())
    const organizationId = yield select(makeSelectAuthSelectedOrganizationId())

    try {
        const response = yield call(deleteIbmBucket, token, organizationId)
        if (response.ok) {
            yield put(ibmDeleteBucketSuccess())

        } else {
            yield put(ibmDeleteBucketFailure())
        }
    }
    catch(error) {
        yield put(ibmDeleteBucketFailure())
    }
}

export function* getIbmBucketsSaga (action) {

    const token = yield select(makeSelectAuthUserMgtToken())
    const organizationId = yield select(makeSelectAuthSelectedOrganizationId())

    try {
        const response = yield call(getIbmBuckets, token, organizationId)
        if (response.ok) {

            const json = yield response.json()

            if (json) {
                yield put(ibmGetBucketsSuccess(json))
            } else {
                yield put(ibmGetBucketsFailure())
            }

        } else {
            yield put(ibmGetBucketsFailure())
        }
    }
    catch(error) {
        yield put(ibmGetBucketsFailure())
    }
}

export function* getIbmBucketObjectsSaga (action) {

    const token = yield select(makeSelectAuthUserMgtToken())
    const organizationId = yield select(makeSelectAuthSelectedOrganizationId())

    try {
        const response = yield call(getIbmBucketObjects, token, organizationId)
        if (response.ok) {

            const json = yield response.json()

            if (json) {
                yield put(ibmGetBucketObjectsSuccess(json))
            } else {
                yield put(ibmGetBucketObjectsFailure())
            }

        } else {
            yield put(ibmGetBucketObjectsFailure())
        }
    }
    catch(error) {
        yield put(ibmGetBucketObjectsFailure())
    }
}