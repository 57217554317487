export const CLOUD_SENSE_SAVE_USER_PROFILE = 'CLOUD_SENSE_SAVE_USER_PROFILE'
export const CLOUD_SENSE_SAVE_USER_PROFILE_SUCCESS = 'CLOUD_SENSE_SAVE_USER_PROFILE_SUCCESS'
export const CLOUD_SENSE_SAVE_USER_PROFILE_FAILURE = 'CLOUD_SENSE_SAVE_USER_PROFILE_FAILURE'

export const CLOUD_SENSE_GET_USER_PROFILE = 'CLOUD_SENSE_GET_USER_PROFILE'
export const CLOUD_SENSE_GET_USER_PROFILE_SUCCESS = 'CLOUD_SENSE_GET_USER_PROFILE_SUCCESS'
export const CLOUD_SENSE_GET_USER_PROFILE_FAILURE = 'CLOUD_SENSE_GET_USER_PROFILE_FAILURE'

export const personaTypes = [
    'Developer & Data Scientist',
    'DevOps'
]