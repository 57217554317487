/*
 *
 * Spark constants
 *
 */

export const SPARK_MERGE = 'app/Spark/SPARK_MERGE'

export const SPARK_FETCH = 'app/Spark/SPARK_FETCH'
export const SPARK_FETCH_BATCHLIST = 'app/Spark/SPARK_FETCH_BATCHLIST'
export const SPARK_FETCH_BATCH_LOGS = 'app/Spark/SPARK_FETCH_BATCH_LOGS'

export const SPARK_DELETE_BATCH_ITEM = 'app/Spark/SPARK_DELETE_BATCH_ITEM'
export const SPARK_DELETE_BATCH_ITEMS = 'app/Spark/SPARK_DELETE_BATCH_ITEMS'
export const SPARK_SAVE = 'app/Spark/SPARK_SAVE'
export const SPARK_REMOVE_BATCH_LIST_ITEM = 'app/Spark/SPARK_REMOVE_BATCH_LIST_ITEM'
export const SPARK_REMOVE_BATCH_LIST_ITEMS = 'app/Spark/SPARK_REMOVE_BATCH_LIST_ITEMS'
