import {
    GCP_CREATE_BUCKET,
    GCP_CREATE_BUCKET_FAILURE,
    GCP_CREATE_BUCKET_SUCCESS,
    GCP_DELETE_BUCKET,
    GCP_DELETE_BUCKET_FAILURE,
    GCP_DELETE_BUCKET_SUCCESS,
    GCP_LIST_BUCKETS,
    GCP_LIST_BUCKETS_FAILURE,
    GCP_LIST_BUCKETS_SUCCESS,
    GCP_GET_BUCKET_OBJECTS,
    GCP_GET_BUCKET_OBJECTS_FAILURE,
    GCP_GET_BUCKET_OBJECTS_SUCCESS
} from './GcpStorageConstants'

const initialState = {
    buckets: [],
    isFetching: false,
    isCreating: false,
    isDeleting: false
}

export default function reducer(state=initialState, action) {
    switch(action.type) {
        case GCP_CREATE_BUCKET:
            return {
                ...state,
                isCreating: true
            }
        case GCP_CREATE_BUCKET_FAILURE:
            return {
                ...state,
                isCreating: false
            }
        case GCP_CREATE_BUCKET_SUCCESS:
            return {
                ...state,
                isCreating: false
            }
        case GCP_DELETE_BUCKET:
            return {
                ...state,
                isDeleting: true
            }
        case GCP_DELETE_BUCKET_SUCCESS:
            return initialState
        case GCP_DELETE_BUCKET_FAILURE:
            return {
                ...state,
                isDeleting: false
            }
        case GCP_LIST_BUCKETS:
            return {
                ...state,
                isFetching: true
            }
        case GCP_LIST_BUCKETS_SUCCESS:
            return {
                ...state,
                buckets: action.payload,
                isFetching: false
            }
        case GCP_LIST_BUCKETS_FAILURE:
            return {
                ...state,
                isFetching: false
            }
        case GCP_GET_BUCKET_OBJECTS:
            return {
                ...state,
                isFetching: true
            }
        case GCP_GET_BUCKET_OBJECTS_SUCCESS:
            return {
                ...state,
                bucketObjects: action.payload,
                isFetching: false
            }
        case GCP_GET_BUCKET_OBJECTS_FAILURE:
            return {
                ...state,
                isFetching: false
            }
        default:
            return state
    }
}