/**
 *
 * UiLoadingFull
 *
 */

import React, { memo } from 'react'
import { LinearProgress, Container } from '@material-ui/core'

function UiLoadingFull() {
  return (
    <div>
      <LinearProgress />
    </div>
  )
}

UiLoadingFull.propTypes = {}

export default memo(UiLoadingFull)
