import BannerLogo from './banner-logo.png'
import BoxIcon from './box.png'
import CloudIcon from './cloud_icon.png'
import CloudWhiteIcon from './cloud_icon_white.png'
import CloudPersonaIcon from './cloud_persona_icon.png'
import FunctionIcon from './function.png'
import KubernetesIcon from './kubernetes.png'
import HomeIcon from './home.png'
import LogoutIcon from './logout.png'
import ProcessIcon from './process.png'
import StorageIcon from './storage.png'
import GoogleGkeIcon from './google_gke.png'
import AmazonEksIcon from './amazon_eks.png'
import AzureAksIcon from './azure_aks.png'
import FaveIcon from './favicon.ico'
import NodeIcon from './node.png'
import PodIcon from './pod.png'
import DeploymentIcon from './deployment.png'
import ClusterEnvironmentIcon from './cluster_environment.svg'
import ClusterTypeIcon from './cluster_type.svg'
import CloudProviderIcon from './cloud_provider.svg'
import ClusterRegionIcon from './cluster_region.svg'
import ClusterDeploymentsIcon from './cluster_deployments.svg'
import ClusterSummaryIcon from './cluster_summary.svg'
import GrainyBackground from './grainy-background-short.jpg'
import UlapLogo from './ulap-logo.svg'
import UlapIcon from './ulapIcon.svg'
import ServerNetworkIcon from './server_network.svg'
import BitBucketLogo from './bitbucket.png'
import DockerLogo from './docker-logo.png'
import GithubLogo from './github.png'
import GitlabLogo from './gitlab.png'
import GcpLogo from './gcp.png'
import IBMIksIcon from './ibm_iks.png'
import NewClusterIcon from './newCluster.svg'
import ExistingClusterIcon from './existCluster.svg'
import CloudGroupImage from './cloud-group.png'
import SparkIcon from './spark.svg'
import FlinkIcon from './flink.svg'
import CodeIcon from './code.png'
import DevOpsIcon from './devops.png'
import AppsIcon from './apps.svg'
import DataIcon from './data_gray.svg'
import DataWhiteIcon from './data_white.svg'
import WorkspaceIcon from './workspace_gray.svg'
import WorkspaceWhiteIcon from './workspace_white.svg'
import HomeSvgIcon from './home.svg'
import ClusterIcon from './cluster.svg'
import CogIcon from './cog.svg'
import ClockIcon from './clock.svg'
import ClockWhiteIcon from './clock_white.svg'
import DeployAppsIcon from './deploy_apps_gray.svg'
import JobIcon from './job_gray.svg'
import JobWhiteIcon from './job_white.svg'
import QuestionMarkIcon from './question-mark.svg'
import SwitchPersonaIcon from './switch_persona.svg'
import DeveloperPersonaIcon from './developer-persona.svg'
import DevOpsPersonaIcon from './devops-persona.svg'
import PostgresIcon from './postgres.svg'
import MinioIcon from './minio_icon.svg'
import ElasticSearchIcon from './elasticsearch.svg'
import NifiIcon from './nifi.svg'
import LogstashIcon from './logstash.svg'
import TrinoIcon from './trino_icon.svg'

import CreateWorkspaceIcon from './create_workspace_icon.svg'
import NewDeployAppsIcon from './new_deploy_apps_icon.svg'
import CreateBucketIcon from './create_bucket_icon.svg'
import SubmitJobsIcon from './submit_jobs_icon.svg'
import FilledDataIcon from './filled_data_icon.svg'
import DataProcessIcon from './data_processing_icon.svg'
import DataMovementIcon from './data_movement_icon.svg'
import KibanaIcon from './kibana_icon.svg'
import KubeflowIcon from './kubeflow_icon.svg'
import JupyterIcon from './jupyter_icon.svg'
import AIMLIcon from './ai_ml_icon.svg'

export {
  AmazonEksIcon,
  AppsIcon,
  AzureAksIcon,
  IBMIksIcon,
  BannerLogo,
  BoxIcon,
  ClockIcon,
  CloudIcon,
  CloudWhiteIcon,
  ClusterIcon,
  CodeIcon,
  CogIcon,
  DataIcon,
  DeployAppsIcon,
  DevOpsIcon,
  FaveIcon,
  FlinkIcon,
  FunctionIcon,
  HomeIcon,
  HomeSvgIcon,
  KubernetesIcon,
  GoogleGkeIcon,
  LogoutIcon,
  JobIcon,
  ProcessIcon,
  StorageIcon,
  NodeIcon,
  PodIcon,
  DeploymentIcon,
  ClusterEnvironmentIcon,
  ClusterTypeIcon,
  CloudProviderIcon,
  ClusterRegionIcon,
  ClusterDeploymentsIcon,
  ClusterSummaryIcon,
  GrainyBackground,
  UlapLogo,
  UlapIcon,
  ServerNetworkIcon,
  BitBucketLogo,
  DockerLogo,
  GithubLogo,
  GitlabLogo,
  GcpLogo,
  NewClusterIcon,
  QuestionMarkIcon,
  ExistingClusterIcon,
  CloudGroupImage,
  SparkIcon,
  WorkspaceIcon,
  WorkspaceWhiteIcon,
  ClockWhiteIcon,
  DataWhiteIcon,
  JobWhiteIcon,
  SwitchPersonaIcon,
  DeveloperPersonaIcon,
  DevOpsPersonaIcon,
  PostgresIcon,
  MinioIcon,
  NifiIcon,
  ElasticSearchIcon,
  LogstashIcon,
  CreateWorkspaceIcon,
  NewDeployAppsIcon,
  CreateBucketIcon,
  SubmitJobsIcon,
  FilledDataIcon,
  CloudPersonaIcon,
  DataProcessIcon,
  DataMovementIcon,
  KibanaIcon,
  KubeflowIcon,
  JupyterIcon,
  AIMLIcon,
  TrinoIcon
}
