/*
 *
 * Cluster reducer
 *
 */
import produce from 'immer'
import { DEFAULT_ACTION, CLUSTER_MERGE } from './constants'
import { merge } from '../../utils/object'

export const initialState = {
  isLoading: false,
  isLoadingCreate: false,
  error: '',
  message: '',
  kubernetes: [],
  sparkStatusList: {},
  isUpdated: false,
  details: null,
  overview: {},
}

/* eslint-disable default-case, no-param-reassign */
const clusterReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CLUSTER_MERGE:
        return merge(draft, action.payload)
    }
  })

export default clusterReducer
