/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill'

// Import all the third party stuff
import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import CssBaseline from '@material-ui/core/CssBaseline'
import history from 'utils/history'
import 'sanitize.css/sanitize.css'

const App = lazy(() => import('./containers/App'))

import { ThemeProvider } from '@material-ui/core/styles'
// Import Language Provider
import LanguageProvider from './containers/LanguageProvider'

import GlobalStyle from './global-styles'
import { runStorageInit } from './service/storage'
import AppLoading from './components/AppLoading'

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicon.ico'
import 'file-loader?name=.htaccess!./.htaccess'
/* eslint-enable import/no-unresolved, import/extensions */

import configureStore from './configureStore'

// Import i18n messages
import { translationMessages } from './i18n'
import { AlertSnack } from './components'
import { projectTheme } from './material_theme'

// Create redux store with history
const initialState = {}
const store = configureStore(initialState, history)
const MOUNT_NODE = document.getElementById('app')

const render = async messages => {
  await runStorageInit()

  ReactDOM.render(
    <Suspense fallback={<AppLoading />}>
      <ThemeProvider theme={projectTheme}>
        <Provider store={store}>
          <LanguageProvider messages={messages}>
            <ConnectedRouter history={history}>
              <CssBaseline />
              <AlertSnack />
              <GlobalStyle />
              <App />
            </ConnectedRouter>
          </LanguageProvider>
        </Provider>
      </ThemeProvider>
    </Suspense>,
    MOUNT_NODE,
  )
}

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    render(translationMessages)
  })
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'))
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err
    })
} else {
  render(translationMessages)
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install() // eslint-disable-line global-require
}
