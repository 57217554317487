import {
    IBM_SAVE_CREDENTIALS,
    IBM_SAVE_CREDENTIALS_FAILURE,
    IBM_SAVE_CREDENTIALS_SUCCESS,
    IBM_DELETE_CREDENTIALS,
    IBM_DELETE_CREDENTIALS_SUCCESS,
    IBM_DELETE_CREDENTIALS_FAILURE,
    IBM_GET_CREDENTIALS,
    IBM_GET_CREDENTIALS_SUCCESS,
    IBM_GET_CREDENTIALS_FAILURE
} from './ibmAuthConstants'

const initialState = {
    apiKey: '',
    isAuthenticated: false,
    isGetting: false,
    isSaving: false,
    isDeleting: false
}

export default function reducer(state=initialState, action) {
    switch(action.type) {
        case IBM_SAVE_CREDENTIALS:
            return {
                ...state,
                isSaving: true
            }
        case IBM_SAVE_CREDENTIALS_FAILURE:
            return {
                ...state,
                isSaving: false
            }
        case IBM_SAVE_CREDENTIALS_SUCCESS:
            return {
                ...state,
                apiKey: action.payload.apiKey,
                isAuthenticated: true,
                isSaving: false
            }
        case IBM_DELETE_CREDENTIALS:
            return {
                ...state,
                isDeleting: true
            }
        case IBM_DELETE_CREDENTIALS_SUCCESS:
            return initialState
        case IBM_DELETE_CREDENTIALS_FAILURE:
            return {
                ...state,
                isDeleting: false
            }
        case IBM_GET_CREDENTIALS:
            return {
                ...state,
                isGetting: true
            }
        case IBM_GET_CREDENTIALS_SUCCESS:
            return {
                ...state,
                apiKey: action.payload.apiKey,
                isAuthenticated: true,
                isGetting: false
            }
        case IBM_GET_CREDENTIALS_FAILURE:
            return {
                ...state,
                isGetting: false
            }

        default:
            return state
    }
}